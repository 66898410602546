import { FC, memo, useState } from 'react';
import { useMutation } from 'react-query';
import MaskedInput from 'react-text-mask';
import { useBoolean } from 'react-use-boolean';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';
import { Button } from 'components/Button';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import emailMask from 'text-mask-addons/dist/emailMask';
import * as Yup from 'yup';
import zxcvbn from 'zxcvbn';

import { api } from '@/api';
import { DEFAULT_LOCALE, PASSWORD_STRENGTH_SCORE } from '@/constants/global';
import { PasswordField } from '@/features/PasswordField';
import { sendGTMEvent } from '@/utils';
import { getErrorMessage } from '@/utils/errors';
import { phoneMask } from '@/utils/masks';

type Props = {
  countryCode: string;
};

export const Register: FC<Props> = memo(({ countryCode }) => {
  const { t } = useTranslation();
  const [showRegisterButton, { off: hideRegisterButton }] = useBoolean(true);

  const router = useRouter();

  const [message, setMessage] = useState<string | null>(null);
  const [messagePositive, { on: setMessagePositive, off: setMessageNegative}] = useBoolean(false)

  const initialValues = {
    clientType: 'private',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: countryCode,
    language: router.locale || DEFAULT_LOCALE,
    password: '',
    city: '',
    address: '',
    postIndex: '',
    test: false,
    termsAndConditions: false,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('errors.requiredField')),
    lastName: Yup.string().required(t('errors.requiredField')),
    phone: Yup.string().required(t('errors.requiredField')),
    email: Yup.string().email(t('errors.incorrectEmail')).required(t('errors.requiredField')),
    password: Yup.string()
      .min(3, t('errors.passwordLength'))
      .max(128, t('errors.passwordLength'))
      .test('strength', t('errors.passwordStrength'), (val: any) => {
        const passwordStrength = zxcvbn(val && val.toString());

        return passwordStrength.score >= PASSWORD_STRENGTH_SCORE;
      })
      .required(t('errors.requiredField')),
    termsAndConditions: Yup.boolean().oneOf([true], t('errors.termsAndConditions')),
  });

  const { isLoading, mutate: handleRegister } = useMutation(api.user.registerWithEmail, {
    onSuccess: () => {
      setMessagePositive()
      sendGTMEvent(`sign_up`)
      setMessage(t('success.registration'));
      hideRegisterButton();
    },
    onMutate: () => {
      setMessage(null);
    },
    onError: (error) => {
      setMessageNegative()
      setMessage(t(getErrorMessage(error)));
    },
  });

  const styles =
    'w-full placeholder:text-gray-500 rounded-xl text-lg py-2 px-4 ' +
    'border border-gray-200 bg-white outline-[#257AEA]';

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(formData) => handleRegister(formData)}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}>
      <Form className="px-10">
        <div className="grid md:grid-cols-2 gap-x-10 gap-y-4 md:gap-y-8">
          <div>
            <Field className={styles} name="firstName" placeholder={t('firstName')} type="text" />
            <ErrorMessage className="pt-1 text-sm text-red-500" component="p" name="firstName" />
          </div>
          <div>
            <Field className={styles} name="lastName" placeholder={t('lastName')} type="text" />
            <ErrorMessage className="pt-1 text-sm text-red-500" component="p" name="lastName" />
          </div>
          <div>
            <Field className={styles} name="email">
              {({ field }: { field: { name: string } }) => (
                <MaskedInput className={styles} mask={emailMask} placeholder={t('email')} type="text" {...field} />
              )}
            </Field>
            <ErrorMessage className="pt-1 text-sm text-red-500" component="p" name="email" />
          </div>
          <div>
            <Field name="phone">
              {({ field }: { field: { name: string } }) => (
                <MaskedInput className={styles} mask={phoneMask} placeholder={t('phone')} type="phone" {...field} />
              )}
            </Field>

            <ErrorMessage className="pt-1 text-sm text-red-500" component="p" name="phone" />
          </div>
          <div>
            <PasswordField className={styles} />
          </div>
        </div>
        <div className="flex flex-col items-start my-6 md:items-center md:my-8 align-start">
          <label className="flex items-start gap-3 text-gray-900 md:items-center">
            <Field
              className="text-sm text-blue-600 bg-gray-100 border-gray-300 w-7 md:w-4 h-7 md:h-4 focus:ring-blue-500 focus:ring-2"
              name="termsAndConditions"
              style={{ borderRadius: '0!important' }}
              type="checkbox"
            />
            {t('termsAndConditions')}
          </label>
          <ErrorMessage className="pt-1 text-sm text-red-500" component="p" name="termsAndConditions" />
        </div>
        {message && <div className={cn("block w-full text-center ", {
          "text-red-500": !messagePositive,
          "text-green-500": messagePositive,
        })}>{message}</div>}
        {showRegisterButton ? (
          <Button className="mx-auto mt-4 md:mt-8" isPending={isLoading} title={t('createAnAccount')} type="submit" />
        ) : null}
      </Form>
    </Formik>
  );
});
